export const plainObject = () => Object.create(null);

export const checkValueType = (key: string, value: any) => {
  const nonJsonTypes = ['undefined', 'symbol', 'function'];
  const type = typeof value;

  if (nonJsonTypes.includes(type)) {
    throw new TypeError(
      `Setting a value of type \`${type}\` for key \`${key}\` is not allowed as it's not supported by JSON`
    );
  }
};
