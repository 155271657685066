import { nanoid } from 'nanoid';
import axios, { AxiosInstance } from 'axios';
import { EventEmitter } from 'events';
import { uniq } from 'lodash';
import Flag from './Flag';
import {
  BatchEvalResult,
  Context,
  ContextCreator,
  EvalResult,
  ExecutorEvent,
  ExecutorProps
} from './type'


export default class Executor {
  _name: string;

  _session: AxiosInstance;

  _endpoint: string;

  _emitter: EventEmitter;

  _batchMode: boolean;

  createContext?: ContextCreator;

  constructor(props: ExecutorProps) {
    const { endpoint, timeoutMs, name, batchMode = false, createContext } = props;
    this.createContext = createContext;
    this._name = name || nanoid(4);
    this._endpoint = endpoint;
    this._emitter = new EventEmitter();
    this._batchMode = batchMode;
    this._session = axios.create({
      baseURL: endpoint,
      timeout: timeoutMs,
    });
  }

  get batchMode() {
    return this._batchMode;
  }

  get endpoint() {
    return this._endpoint;
  }

  get name() {
    return this._name;
  }

  set batchMode(value: boolean) {
    if (this._batchMode !== value) {
      this._batchMode = value;
      this._emitter.emit(ExecutorEvent.switchMode, value ? 'batch' : 'each');
    }
  }

  setContextCreator(contextCreator: ContextCreator) {
    this.createContext = contextCreator;
  }

  on(event: ExecutorEvent, listener: (...args: any[]) => void) {
    this._emitter.on(event, listener);

    return () => {
      this._emitter.off(event, listener);
    };
  }

  once(event: ExecutorEvent, listener: (...args: any[]) => void) {
    this._emitter.once(event, listener);

    return () => {
      this._emitter.off(event, listener);
    };
  }

  off(event: ExecutorEvent, listener: (...args: any[]) => void) {
    this._emitter.off(event, listener);
  }

  toString() {
    return `<Executor:${this._name}:${this._batchMode ? 'batch' : 'each'}>`;
  }

  execute(flags: Flag[]) {
    return this._batchMode ? this._batchEvaluation(flags) : this._eachEvaluation(flags);
  }

  async _eachEvaluation(flags: Flag[]) {
    const evaluationPromises = flags.map(async (flag) => {
      let context: Context | undefined;
      try {
        const createContext = this.createContext || flag.createContext;
        if (!createContext) {
          throw new Error(`createContext Not defined at either exeucter or Falg:${flag.name}`);
        }
        context = createContext();
        const result = await this._session.post<EvalResult>('/evaluation', {
          ...context,
          flagKey: flag.key,
        });
        const runtimeContext = {
          result: result.data,
          context,
        };
        flag.apply(runtimeContext);
        this._emitter.emit(ExecutorEvent.flagEvaluationSuccess, flag, runtimeContext);
      } catch (error) {
        this._emitter.emit(ExecutorEvent.evaluationError, error, flag, context);
      }
    });

    await Promise.all(evaluationPromises);
    this._emitter.emit(ExecutorEvent.evaluationSuccess);
    return flags;
  }

  async _batchEvaluation(flags: Flag[]) {
    let context;
    try {
      const entities: Context[] = [];
      const keys: string[] = [];

      if (this.createContext) {
        const { entityContext, ...other } = this.createContext();
        entities.push({
          entityContext: {
            ...entityContext,
            _executorEntity: true,
          },
          ...other,
        });
      }

      for (const flag of flags) {
        keys.push(flag.key);

        if (!this.createContext) {
          if (!flag.createContext) {
            throw new Error(`createContext Not defined at either exeucter or Falg:${flag.name}`);
          }

          const { entityContext, ...other } = flag.createContext();
          entities.push({
            entityContext: {
              ...entityContext,
              _targetKey: flag.key,
            },
            ...other,
          });
        }
      }
      const context = {
        entities,
        flagKeys: uniq(keys),
      };
      const restuls = await this._session.post<{
        evaluationResults: BatchEvalResult[];
      }>('/evaluation/batch', context);

      restuls.data.evaluationResults.forEach(({ evalContext: context, ...result }) => {
        if (
          context.flagKey === context.entityContext._targetKey ||
          context.entityContext._executorEntity
        ) {
          const runtimeContext = {
            result,
            context,
          };
          const flag = flags.find((f) => f.key === context.flagKey);
          flag?.apply(runtimeContext);
        }
      });

      this._emitter.emit(ExecutorEvent.batchEvaluationSuccess, restuls.data);
    } catch (error) {
      this._emitter.emit(ExecutorEvent.batchEvaluationError, error, context);
    }
    return flags;
  }
}
