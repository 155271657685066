export type ContextFundamentalType = boolean | number | string;
export type EntityContext = Record<string, ContextFundamentalType>;
export type Comparator = <T>(oldValue: T, newValue: T) => boolean;
export type ContextCreator =  () => Context;
export enum FlagEvent {
  applyStart = 'apply-start',
  applyNotMatch = 'apply-not-match',
  applyCompare = 'apply-compare',
  applySuccess = 'apply-success',
  applyError = 'apply-error',
  applySkip = 'apply-skip',
}
export interface FlagProps {
  key: string;
  schema?: object;
  createContext?(): Context;
  name?: string;
  comparator?<T>(oldValue: T, newValue: T): boolean;
}

export type Context = {
  entityID?: number | string;
  entityContext: EntityContext;
};

export type RuntimeContext = {
  result: EvalResult;
  context: Context;
};

export interface EvalResult<T extends object = {}> {
  flagID?: number;
  flagSnapshotID?: number;
  segmentID?: number;
  variantKey?: string;
  variantAttachment?: T;
  evalContext?: Context;
  timestamp?: string;
}


export interface ExecutorProps {
  endpoint: string;
  timeoutMs?: number;
  name?: string;
  batchMode?: boolean;
  createContext?: ContextCreator;
}

export interface BatchEvalResult extends EvalResult {
  evalContext: Context & { flagKey: string };
}

export enum ExecutorEvent {
  flagEvaluationSuccess = 'flag-evaluation-success',
  evaluationError = 'evaluation-error',
  evaluationSuccess = 'evaluation-success',
  batchEvaluationSuccess = 'batch-evaluation-success',
  batchEvaluationError = 'batch-evaluation-error',
  switchMode = 'switch-mode',
}


export interface RunnerProps extends ExecutorProps {
  intervalMs?: number;
}
export enum RunnerStatus {
  ready = 'ready',
  running = 'running',
  stop = 'stop',
}
export enum IntervalEvent {
  startInterval = 'start-interval',
  start = 'start',
  stop = 'stop',
}
export const RunnerEvent = {...ExecutorEvent, ...IntervalEvent};
export type RunnerEvent = IntervalEvent | ExecutorEvent;


export interface SingletonFlagProps extends FlagProps {
  endpoint: string;
  timeoutMs?: number;
  createContext: () => Context;
}

export enum SingletonFlagEvent {
  requestStart = 'request-start',
  requestSuccess = 'request-success',
  requestError = 'request-error',
  requestConflict = 'request-conflict',
}
