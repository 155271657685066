import { EventEmitter } from 'events';
import Ajv from 'ajv';
import _, { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';
import { plainObject, checkValueType } from './utils';

type Options = {
  schema: object;
  name?: string;
};
export type ChangeCallback = (newValue: any, oldValue: any) => void;
export default class Store {
  _validator: Ajv.ValidateFunction;

  _name: string;

  _options: Options;

  _defaultValues: Record<string, any>;

  _values: Record<string, any>;

  _emitter: EventEmitter;

  constructor(options: Options) {
    this._name = options.name || nanoid(4);
    this._options = options;
    this._defaultValues = {};
    this._values = {};
    this._emitter = new EventEmitter();
    if (typeof options.schema !== 'object') {
      throw new TypeError('The `schema` option must be an object.');
    }

    const ajv = new Ajv({
      allErrors: true,
      format: 'full',
      useDefaults: true,
    });

    this._validator = ajv.compile(options.schema);

    this._emitter.emit('store-init');
  }

  toString() {
    return `<Store@${this._name}>`;
  }

  validate(data: any) {
    const valid = this._validator(data);
    if (!valid) {
      const errors = this._validator.errors!.reduce(
        (error, { dataPath, message }) => `${error} \`${dataPath.slice(1)}\` ${message};`,
        ''
      );
      throw new Error(`${this} schema violation:${errors.slice(0, -1)}`);
    }
  }

  setDefaults(data: any) {
    this.validate(data);
    this._defaultValues = _.cloneDeep(data);
  }

  get(key: string, defaultValue?: any) {
    return _.get(this.store, key, defaultValue);
  }

  set(key: string | Object, value: any) {
    const { store } = this;

    const set = (key: string, value: any) => {
      checkValueType(key, value);
      _.set(store, key, value);
    };

    if (typeof key === 'object') {
      const object = key;
      for (const [key, value] of Object.entries(object)) {
        set(key, value);
      }
    } else {
      set(key, value);
    }

    this.store = store;
  }

  has(key: string) {
    return _.has(this.store, key);
  }

  clear() {
    this.store = plainObject();
  }

  onDidChange(key: string, callback: ChangeCallback) {
    if (typeof key !== 'string') {
      throw new TypeError(`Expected \`key\` to be of type \`string\`, got ${typeof key}`);
    }

    if (typeof callback !== 'function') {
      throw new TypeError(
        `Expected \`callback\` to be of type \`function\`, got ${typeof callback}`
      );
    }

    const getter = () => this.get(key);

    return this._handleChange('change', getter, callback);
  }

  onDidAnyChange(callback: ChangeCallback) {
    if (typeof callback !== 'function') {
      throw new TypeError(
        `Expected \`callback\` to be of type \`function\`, got ${typeof callback}`
      );
    }

    const getter = () => cloneDeep(this.store);

    return this._handleChange('anyChange', getter, callback);
  }

  _handleChange(event: 'anyChange' | 'change', getter: () => any, callback: ChangeCallback) {
    let currentValue = getter();

    const onChange = () => {
      const oldValue = currentValue;
      const newValue = getter();

      if (!_.isEqual(newValue, oldValue)) {
        currentValue = newValue;
        callback.call(this, newValue, oldValue);
      }
    };

    this._emitter.on(event, onChange);
    return () => this._emitter.removeListener(event, onChange);
  }

  get size() {
    return Object.keys(this.store).length;
  }

  get store() {
    return this._values;
  }

  set store(value) {
    this.validate(value);
    this._values = _.cloneDeep(value);

    this._emitter.emit('anyChange');
    this._emitter.emit('change');
  }

  get defaults() {
    return this._defaultValues;
  }
}
